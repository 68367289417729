import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AppBar, Skeleton, Toolbar, Typography, Divider } from '@mui/material';

// Custom Components
import UserMenu from 'components/userMenu/userMenu';
import { AccountCircleIcon, BusinessIcon, WhiteLogoIcon } from 'components/icons/icons';

// Contexts
import { UserInfoContext } from 'contexts/userInfoContext/userInfoContext';

// Text
import { HEALTHWISE_LOGO_LINK_ARIA, APPLICATION_DASHBOARD_PATH } from 'utils/dictionary';
import { TenantInfoContext } from 'contexts/tenantInfoContext/tenantInfoContext';

const StyledAppBar = styled(AppBar)`
  background: ${props => props.theme.colors.darkPrimary};
  color: ${props => props.theme.colors.white};
  box-shadow: none;
`;

// Style the Profile Component, padding takes into account the appbar 'gutter'
const StyledProfile = styled.div`
  background: ${props => props.theme.colors.primary};
  height: 100%;
  min-width: 180px;
  padding: 0px 0px 0px ${props => props.theme.spacingScale[3]};
`;

// Vertically centers the div in the parent container
const StyledProfileBlock = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

// Set the boundries of the logo
const StyledLogo = styled(WhiteLogoIcon)`
  width: 160px;
  padding-left: ${props => props.theme.spacingScale[4]};
`;

const StyledAccountIcon = styled(AccountCircleIcon)`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.typeScale[7]};
  vertical-align: middle;
  display: inline-flex;
  padding-right: 10px;
  padding-top: 5px;
`;

const StyledSkeleton = styled(Skeleton)`
  background-color: ${props => props.theme.colors.primaryTextOnDark};
  min-width: ${props => props.theme.typeScale[7]};
  display: inline-block;
`;

const StyledName = styled.span`
  font-size: ${props => props.theme.typeScale[5]};
  line-height: ${props => props.theme.spacingScale[4]};
  vertical-align: middle;
`;

const StyledSkipLink = styled.a`
  width: 1px;
  height: 1px;
  overflow: hidden;
  &:focus {
    position: relative;
    width: auto;
    height: auto;
    top: 0;
    left: 0;
    padding: 0 4px;
    background-color: white;
    z-index: 100;
  }
`;

const Header = () => {
  let userInfo = useContext(UserInfoContext) ?? {};
  const tenants = useContext(TenantInfoContext);
  const [tenantName, setTenantName] = useState(null);
  const { search } = useLocation();

  useEffect(() => {
    if (tenants) {
      setTenantName(tenants[0].name);
    }
  }, [tenants]);

  return (
    <>
      <StyledSkipLink
        aria-label="Select to skip navigation"
        href="#main"
        onClick={e => {
          e.preventDefault();
          const $main = document.querySelector('main');
          if ($main?.focus) {
            $main.focus();
          }
        }}
      >
        skip navigation
      </StyledSkipLink>
      <StyledAppBar position="absolute">
        <Toolbar disableGutters={true}>
          <Link
            aria-label={HEALTHWISE_LOGO_LINK_ARIA}
            to={{
              pathname: APPLICATION_DASHBOARD_PATH,
              search: search
            }}
            alt="Healthwise - for every health decision."
          >
            <StyledLogo />
          </Link>

          <Divider 
            orientation="vertical"
            variant="middle"
            light={true}
            flexItem={true}
            sx={{ bgcolor: "white", marginLeft: "1em", marginRight: "1em" }} />

          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            MyHealthwise
          </Typography>

          {tenantName &&
            <div style={{ 
              display: 'flex', 
              alignItems: 'center'
            }}>
              <BusinessIcon style={{ marginRight: ".5em"}} />
              <Typography variant="h5" component="div">{tenantName}</Typography>
            </div>
          }

          <Divider 
            orientation="vertical"
            variant="middle"
            light={true}
            flexItem={true}
            sx={{ bgcolor: "white", marginLeft: "1em" }} />

          <StyledProfile>
            <StyledProfileBlock>
              {userInfo?.status === 'success' && (
                <div>
                  <StyledAccountIcon />
                  <StyledName>
                    {userInfo.data?.data?.firstName ? (
                      userInfo.data?.data?.firstName
                    ) : (
                      <StyledSkeleton variant="text" />
                    )}
                  </StyledName>
                  <UserMenu user={userInfo.data?.data} />
                </div>
              )}
            </StyledProfileBlock>
          </StyledProfile>
        </Toolbar>
      </StyledAppBar>
    </>
  );
};

export default Header;
